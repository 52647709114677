$default_font_size: 0.9375rem;
$default_font_family: "Montserrat", sans-serif;
$numbers_font_family: 'Roboto Mono', monospace;


$title_font: normal bold normal 1.0rem/normal $default_font_family;
$title_medium_font: normal bold normal 1.0rem/normal $default_font_family;
$title_smaller_font: normal bold normal 0.95rem/normal $default_font_family;

$menu_font: normal normal normal 1.25rem/normal $default_font_family;
$meeting_headline_font: normal bold normal 18px/normal $default_font_family;
$meeting_datetime_font: normal bold normal 14px/normal $default_font_family;

$committee_description_font:  normal normal normal 16px/normal $default_font_family;
