@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;1,400;1,600&family=Roboto+Mono&family=Ubuntu+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@import 'assets/styles/icons';

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import './../node_modules/primeicons/primeicons.css';

@import './../node_modules/quill/dist/quill.core.css';
@import './../node_modules/quill/dist/quill.snow.css';

@import './../node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css';
@import './../node_modules/primeng/resources/primeng.min.css';
@import './../node_modules/bootstrap/dist/css/bootstrap.min.css';


@import 'assets/styles/fonts.scss';
@import 'assets/styles/colors.scss';

@import 'assets/styles/primeng.scss';
@import 'assets/styles/bootstrap.scss';

body, html {
    font-family: 'Montserrat', sans-serif;
    font-size: $default_font_size;
}

h2 {
    font: $title_font;
    margin: 2.5em 0 1em;

    @include media-breakpoint-down(sm) {
        margin: 1.5em 0 0.75em;
    }
}

h3 {
    font: $title_medium_font;
    margin: 2.0em 0 1em;

    @include media-breakpoint-down(sm) {
        margin: 1.25em 0 0.70em;
    }
}

h4 {
    font: $title_smaller_font;
    margin: 1.5em 0 1em;
}

form label.required:after {
    content: "*";
    color: #dc3545;
    padding-left: 0.25rem;
}

.square-photo-preview {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
    height: 0;

    img {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        overflow: hidden !important;
        object-fit: contain;
    }
}

.live {
    color: $meeting_live_color !important;

    &:before {
        content: '';
        width: 0.5em;
        height: 0.5em;
        display: inline-block;
        margin-right: 0.5em;
        background-color: $meeting_live_color;
        vertical-align: middle;
        border-radius: 50%;
        animation: pulse-animation 1s infinite;
    }
}

.pulse {
    animation: pulse-animation 1s infinite;
}

.edit-icon {
    margin-left: 12px;
    font-size: 0.85em;
    border: 1px solid #aaa;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    line-height: 1.5;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.5);
}

@keyframes pulse-animation {
    0% {
        transform: scale(1);
        opacity: .25
    }
    50% {
        transform: scale (1, 5);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: .0;
    }
}


.pulse-icon {
    overflow: visible;
    position: relative;
}
.pulse-icon:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $meeting_live_color;
    border-radius: inherit;
    transition: opacity .3s, transform .3s;
    animation: pulse-icon-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    z-index: 0;
}
@keyframes pulse-icon-animation {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}

.video-wrapper {
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    video, iframe {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        overflow: hidden !important;
        box-shadow: 0 0 2px black;
        z-index: 1;
    }
}
