@import './colors.scss';
@import './fonts.scss';


body {
    .ql-container {
        font-size: 1rem;
    }

    .p-menuitem-text {
        font-family: $default_font_family;
    }

    .btn-primary {
        &:not(:disabled):not(.disabled) {
            background-color: $primary_color;

            &, &:active, &:focus, &:active:focus {
                box-shadow: none;
                border-color: $primary_color;
            }

            &:hover {
                border-color: $primary_color;
                color: $primary_color;
                background-color: $primary_bgcolor;
            }

            &:active:focus {
                border-color: $primary_bgcolor;
                color: $primary_bgcolor;
                background-color: $primary_color;
            }

        }
    }

    .btn-outline-primary {
        &:not(:disabled):not(.disabled) {
            color: $primary_color;
            border-color: $primary_color;

            &,
            &:active,
            &:focus,
            &:active:focus {
                box-shadow: none;
                border-color: $primary_color;
            }

            &:hover,
            &.active:not(:hover) {
                border-color: $primary_bgcolor;
                color: $primary_bgcolor;
                background-color: $primary_color;
            }

            &:active:focus {
                border-color: $primary_color;
                color: $primary_color;
                background-color: $primary_bgcolor;
            }
        }
    }

    .p-dropdown {
        box-shadow: none !important;

        .p-dropdown-panel {

            .p-dropdown-header {
                padding: 0.30rem 0.5rem;
            }

            .p-dropdown-items .p-dropdown-item.p-highlight {
                background-color: $primary_color;
            }
        }

        &:not(.p-disabled).p-focus {
            border-color: $primary_color;
        }
    }

    .is-invalid {
        .p-dropdown,
        &:hover .p-dropdown,
        .p-dropdown.p-dropdown-open {
            border-color: #dc3545;
        }
        .p-dropdown.p-dropdown-open {
            box-shadow: 0 0 0 .2rem rgba(220,53,69,.25) !important;
        }
        .p-dropdown-trigger-icon {
            color: #dc3545;
        }
    }

    .input-group-sm .p-dropdown {
        height: calc(1.5em + .5rem + 2px);

        .p-dropdown-label {
            padding: .25rem .5rem;
            font-size: .875rem;
            line-height: 1.5;
        }
    }



    .p-checkbox .p-checkbox-box.p-highlight {
        background-color: $primary_color;
        border-color: $primary_color;
    }

    .p-checkbox-label {
        margin-bottom: 0 !important;
    }

    .p-tabview {
        a, a:link, a:hover, a:visited {
            text-decoration: none;
        }

        .p-tabview-nav li .p-tabview-nav-link {
            border-bottom-width: 2px;
            border-color: transparent transparent $tab_color_default transparent;
        }

        .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
            border-color: transparent transparent $tab_color_hover transparent;
        }

        .p-tabview-nav li.p-highlight .p-tabview-nav-link {
            border-color: transparent transparent $tab_color_active transparent;
            color: $tab_color_active;
        }
    }

    .p-breadcrumb {
        background: none;
        margin: 0;
        padding: 0.5em 0;

        ul li .p-menuitem {
            word-break: break-word;
        }

        ul li:last-child {
            display: inline-flex;
            align-items: center;

            .p-menuitem-link {
                overflow: hidden;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        ul li .p-menuitem-link:focus,
        ul li .p-menuitem-link:active {
            outline: none;
            box-shadow: none;
        }

        li:not(:last-child)  {
            .p-menuitem-text, .p-menuitem-icon {
                color: $primary_color !important;
            }
        }

        li:not(:last-child) a:hover .p-menuitem-text {
            text-decoration: underline;
        }
    }

    .p-datepicker {
        .p-datepicker-header .p-datepicker-prev:focus,
        .p-datepicker-header .p-datepicker-next:focus {
            box-shadow: none !important;
        }

        .p-datepicker-buttonbar {
            padding: 0.5rem;
        }

        .p-datepicker-calendar thead th { text-align: center }

        table td {
            & > span {
                width: 2.0rem;
                height: 2.0rem;
            }

            &.p-datepicker-today > span.p-highlight,
            & > span.p-highlight {
                background-color: $primary_color;
                box-shadow: none !important;
            }
        }

        .p-timepicker {
            & > div { flex-direction: row-reverse; }
            button > span {transform: rotate(90deg) }
            .p-hour-picker > button {
                margin: 0;
                padding: 0;
            }
        }
    }

    .p-inputtext:enabled:focus {
        box-shadow: 0 0 0 .2rem rgba(0,123,255,.25) !important;
        border-color: #ced4da;
    }

    .is-invalid .p-inputtext {

        &, &:hover {
            border-color: #dc3545;
            padding-right: calc(1.5em + .75rem);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(.375em + .1875rem) center;
            background-size: calc(.75em + .375rem) calc(.75em + .375rem);
        }

        &:focus {
            border-color: #dc3545;
            box-shadow: 0 0 0 .2rem rgba(220,53,69,.25) !important;
        }
    }

    .p-menu {
        width: 15rem;
        max-width: 100%;
    }
}

.p-dialog {
    @media screen and (min-width: 768px) {
        max-width: 70vw;
    }
}
