@font-face {
  font-family: 'icons';
  src: url('../fonts/icons/icons.eot?47180161');
  src: url('../fonts/icons/icons.eot?47180161#iefix') format('embedded-opentype'),
       url('../fonts/icons/icons.woff2?47180161') format('woff2'),
       url('../fonts/icons/icons.woff?47180161') format('woff'),
       url('../fonts/icons/icons.ttf?47180161') format('truetype'),
       url('../fonts/icons/icons.svg?47180161#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icons';
    src: url('../font/icons.svg?47180161#icons') format('svg');
  }
}
*/

[class^="ic-"] {
    width: 1.25em;
    display: inline-block;
    text-align: center;
}

[class^="ic-"]:before, [class*=" ic-"]:before, .ic-default {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
//   margin-right: .2em;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
//   margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.ic-flag:before { content: '\e800'; } /* '' */
.ic-flag-plus:before { content: '\e801'; } /* '' */
.ic-cog:before { content: '\e802'; } /* '' */
.ic-list:before { content: '\e803'; } /* '' */
.ic-next:before { content: '\e804'; } /* '' */
.ic-paperclip:before { content: '\e805'; } /* '' */
.ic-people:before { content: '\e807'; } /* '' */
.ic-people-2nd:before { content: '\e808'; } /* '' */
.ic-people-3rd:before { content: '\e809'; } /* '' */
.ic-plan:before { content: '\e80a'; } /* '' */
.ic-plus:before { content: '\e80c'; } /* '' */
.ic-save:before { content: '\e80e'; } /* '' */
.ic-search:before { content: '\e80f'; } /* '' */
.ic-send:before { content: '\e810'; } /* '' */
.ic-slide:before { content: '\e811'; } /* '' */
.ic-user:before { content: '\e815'; } /* '' */
.ic-voice:before { content: '\e816'; } /* '' */
.ic-arrow-chevron-left:before { content: '\e817'; } /* '' */
.ic-arrow-down:before { content: '\e818'; } /* '' */
.ic-business-user:before { content: '\e819'; } /* '' */
.ic-calendar:before { content: '\e81a'; } /* '' */
.ic-chat:before { content: '\e81b'; } /* '' */
.ic-check:before { content: '\e81c'; } /* '' */
.ic-play:before { content: '\e81e'; } /* '' */
.ic-home:before { content: '\e81f'; } /* '' */
.ic-trash:before { content: '\e820'; } /* '' */
.ic-pencil:before { content: '\e821'; } /* '' */
.ic-city:before { content: '\e822'; } /* '' */
.ic-profile:before { content: '\e823'; } /* '' */
.ic-table-file:before { content: '\e824'; } /* '' */
.ic-table-report:before { content: '\e825'; } /* '' */

.ic-arrow-chevron-right {
    @extend .ic-arrow-chevron-left;
    transform: rotate(180deg)
}
