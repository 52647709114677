@import 'node_modules/bootstrap/scss/mixins/breakpoints';
@import 'variables';
@import 'colors';
@import 'fonts';

.clearfix {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

$positions: static, relative, absolute, fixed, sticky !default;

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        // Common values
        @each $position in $positions {
            .position#{$infix}-#{$position} { position: $position !important; }
        }
    }
}

h2 {
    &:not(.mobile-no-hide) {
        @include media-breakpoint-between(xs, sm, $grid-breakpoints) {
            display: none;
        }
    }
}

.page-item {
    .page-link {
        color: $primary_color;
    }

    &.active .page-link {
        background-color: $primary_color;
        border-color: $primary_color;
    }
}

a.dropdown-item {
    color: #212529;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: $primary_color;
}

.table-responsive thead th a:not(.btn) {
    color: $primary_color;

    &:hover {
        color: #007bff;
    }
}

.btn-danger {
    background-color: #FF7373;
    border-color: #FF7373;
}

.nav-tabs.nav-meeting {
    border: none;
    padding-bottom: 1.25em;

    .nav-link {
        border-width: 2px;
        border-color: transparent transparent $tab_color_default transparent;
        color: $tab_color_hover;
        cursor: pointer;
        text-decoration: none;
        font-weight: bold;

        &:hover {
            border-color: transparent transparent $tab_color_hover transparent;
        }

        &.active {
            color: $tab_color_active;
            border-color: transparent transparent $tab_color_active transparent;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-bottom: 0.5rem;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        max-width: 100% !important;
        width: 100% !important;

        .nav-item {
            min-width: 0;
            flex: 1 1 auto;
        }

        .nav-link {
            padding-left: 1vw;
            padding-right: 1vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }
    }

    @include media-breakpoint-down(xs) {
        .nav-link {
            font-size: 3.3vw;
        }
    }
}

.overflow-visible {
    overflow: visible !important
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $enabled_color;
    background-color: $enabled_color;
}

.text-primary  {
    color: $primary_color !important;
}

.badge-primary {
    background-color: $primary_color !important;
}
