$primary_bgcolor: #fff;
$primary_color: #03A9F4;
$enabled_color: #56CC87;
$description_text: #777;

$sidebar_bgcolor: #f3f3f3;
$sidebar_menu_text: #4F4F4F;
$sidebar_menu_text_hover: $primary_color;

$header_border_color: #c0c0c0;

$meeting_border_color: #E0E0E0;
$meeting_description_color: #828282;
$meeting_headline_color: #333333;
$meeting_live_color: #FF7373;
$meeting_photo_bgcolor: #f0f0f0;

$tab_color_default: #E0E0E0;
$tab_color_hover: #828282;
$tab_color_active: $primary_color;

$calendar_bgcolor: #b3b3b3;
$calendar_live_bgcolor: $meeting_live_color;
$calendar_upcoming_bgcolor: $primary_color;
